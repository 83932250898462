const Intro = () => {
    return (
        <section id="intro">
            <div className="wrapper1280">
                <h1>Portfolio René Tornow</h1>
                <h3>Designer & Frontend-Developer</h3>
                <p>Entdecken Sie mein Portfolio als Designer und Frontend-Entwickler aus der Umgebung von München.</p>
                <p>Durchstöbern Sie meine NFT-Kunstwerke und Sammlerstücke.
                    Einen kleinen Einblick in meine Referenzen sehen Sie direkt hier. Meine gesamte Referenzmappe können Sie unten per E-Mail anfragen. Vieleicht brauchen Sie ja genau mich in Ihrem Team.
                </p>
            </div>
         </section>
    );
};

export default Intro;