import React from 'react';
import 'lightbox2/dist/css/lightbox.min.css';
//@ts-ignore
import lightbox from 'lightbox2/dist/js/lightbox.js' // lightbox2 importieren   ;

const References = () => {

        lightbox.option({
            'resizeDuration': 200,
            'wrapAround': true// Optionen für lightbox2 konfigurieren
        });

    return (
        <section id="references">
            <div className="ref-container">
                <div className="img-container">
                    <a
                        href="/img/ref/bc-werbeagentur-referenz-envision-digital_V2_.webp"
                        data-lightbox="envision"
                        data-title="Envision-referenz"
                    >
                        <h4 className="h4-frame">ENVISION</h4>
                        <img
                            className="cover"
                            src="/img/ref/bc-werbeagentur-referenz-envision-digital_V2_.webp"
                            alt="Envision-referenz"
                        />
                    </a>
                    <a href="/img/ref/bc-werbeagentur-referenz-envision-digital_V3_.webp" data-lightbox="envision" data-title="Envision-referenz">
                    </a>
                    <a href="/img/ref/bc-werbeagentur-referenz-envision-digital_V6_.webp" data-lightbox="envision" data-title="Envision-referenz">
                    </a>
                </div>
                <div className="img-container">
                    <a
                        href="/img/ref/bc-werbeagentur-referenz-tm-ausbau-v4_.webp"
                        data-lightbox="tmausbau"
                        data-title="TM-Ausbau-referenz"
                    >
                        <h4 className="h4-frame">TM-AUSBAU</h4>
                        <img className="cover" src="/img/ref/bc-werbeagentur-referenz-tm-ausbau-v4_.webp"
                             alt="TM-Ausbau-referenz"/>
                    </a>
                    <a href="/img/ref/bc-werbeagentur-referenz-tm-ausbau-v7_.webp" data-lightbox="tmausbau" data-title="TM-Ausbau-referenz">
                    </a>
                    <a href="/img/ref/bc-werbeagentur-referenz-tm-ausbau-v9_.webp" data-lightbox="tmausbau" data-title="TM-Ausbau-referenz">
                    </a>
                </div>
                <div className="img-container">
                    <a
                        href="/img/ref/Beitragsbild-1216xHoehe-5_.webp"
                        data-lightbox="zundapp"
                        data-title="Zündapp-referenz"
                    >
                        <h4 className="h4-frame">ZÜNDAPP</h4>
                        <img className="cover" src="/img/ref/Beitragsbild-1216xHoehe-5_.webp" alt="Zündapp-referenz"/>
                    </a>
                    <a href="/img/ref/Beitragsbild-1216xHoehe-6_.webp" data-lightbox="zundapp" data-title="Zündapp-referenz">
                    </a>
                    <a href="/img/ref/Beitragsbild-1216xHoehe_.webp" data-lightbox="zundapp" data-title="Zündapp-referenz">
                    </a>
                    <a href="/img/ref/Beitragsbild-1216xHoehe-4_.webp" data-lightbox="zundapp" data-title="Zündapp-referenz">
                    </a>
                </div>
                <div className="img-container">
                    <a
                        href="/img/ref/bc-werbeagentur-referenz_egoFM_V4_.webp"
                        data-lightbox="egofm"
                        data-title="EgoFM-referenz">
                        <h4 className="h4-frame">EGO-FM</h4>
                        <img className="cover" src="/img/ref/bc-werbeagentur-referenz_egoFM_V4_.webp"
                             alt="EgoFM-referenz"/>
                    </a>
                    <a href="/img/ref/bc-werbeagentur-referenz_egoFM_V5_.webp" data-lightbox="egofm" data-title="EgoFM-referenz">
                    </a>
                    <a href="/img/ref/bc-werbeagentur-referenz_egoFM_V6_.webp" data-lightbox="egofm" data-title="EgoFM-referenz">
                    </a>
                </div>


            </div>
            <h2>Sie wollen mehr sehen?</h2>
            <p>
                Einen Auszug des gesamten Portfolios sende ich Ihnen gerne zu. Hierzu
                senden Sie mir doch eine kure Nachricht, damit ich weiß, wohin das PDF
                gesendet werden kann.
            </p>
            <button><a href="mailto:portfolio@renetornow.de">portfolio@renetornow.de </a></button>

        </section>
    );
};

export default References;
