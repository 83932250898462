import React from 'react';
import Header from '../components/Header/Header';
import Intro from '../components/Intro/Intro';
import Hobby from '../components/Hobby/Hobby';
import Skills from "../components/Skills/Skills";
import References from "../components/References/References";
import Pandulum from "../components/Pandulum/Pandulum";
import SocialBanner from "../components/SocialBanner/SocialBanner";
import Footer from "../components/Footer/Footer";
import WindowResizeListener from "../helperFunctions/WindowResizeListener";

const Home = () => {
    return (
        <>
            <WindowResizeListener/>
            <Header/>
            <Intro/>
            <Hobby/>
            <Skills/>
            <References/>
            <Pandulum/>
            <SocialBanner/>
            <Footer/>
        </>
    )
};

export default Home;