const Pandulum = () => {
    return (
        <section id="nft-label">
            <div className="bg-mask">
                <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 48.3 50" xmlSpace="preserve">
                    <path className="st0" d="M45.6,24c0-0.3,0-0.6,0-0.9c0-11.9-9.6-21.5-21.5-21.5S2.7,11.2,2.7,23c0,0.3,0,0.6,0,0.9
	c-0.8,1.4-1.2,3.1-1.2,4.8c0,5,3.6,9.2,8.3,10.2c1.7,1.6,3.7,2.9,5.9,3.8c0,0.1,0,0.2,0,0.2c0,3,3.8,5.4,8.5,5.4
	c4.7,0,8.5-2.4,8.5-5.4c0-0.1,0-0.2,0-0.2c2.2-0.9,4.2-2.2,5.9-3.8c4.7-1,8.3-5.2,8.3-10.2C46.8,27.1,46.4,25.4,45.6,24z M11.9,37.5
	c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7C20.6,33.6,16.7,37.5,11.9,37.5z M24.2,46.7c-4,0-6.7-1.9-6.7-3.7
	c0-1.7,2.8-3.7,6.7-3.7s6.7,1.9,6.7,3.7C30.9,44.8,28.1,46.7,24.2,46.7z M36.4,37.5c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7
	s8.7,3.9,8.7,8.7C45.1,33.6,41.2,37.5,36.4,37.5z"/>
</svg>
            </div>
            <div className="nft-container">
                <div className="nft-txt-cont">
                    <h2>Pandulum-Arts.com</h2>
                    <h3>Mein NFT Label</h3>
                    <p>Pandulum-Arts ist die Sammlung meiner Werke, die über 17 Jahre in meinen Skizzenbüchern
                        entstanden sind und nun, dank der Entwicklung von NFTs und digitalen Kunstmärkten, hoffentlich
                        eine Plattform und einen Weg in die öffentlichkeit finden. Erkunden Sie meine Galerien und die
                        dazugehörige Roadmap, um zu sehen, was Sie durch welchen Kauf unterstützten. Für die Ansicht der
                        kompletten Kollektionen folgen Sie bitte dem Link zu OpenSea.io</p>
                    <button><a href="https://opensea.io/Pandulum-Arts" target="_blank" title="Pandulum Arts Opensea" rel="noreferrer">Zum
                        Marktplatz</a></button>
                </div>
                <div className="nft-trio-cont">
                    <figure className="nft-card">
                        <div className="nft-img">
                            <img src="/img/AboutAngels.jpg" alt="About Angels NFT"/>
                        </div>
                        <figcaption className="nft-detail">
                            <p>0.70 ETH</p>
                            <p>opensea.io</p>
                        </figcaption>
                        <p className="nft-name">Angels #1</p>
                        <p className="nft-coll">Unique Artworks</p>
                        <p className="nft-short">Short Information about the NFT <br/>on the Marketplace</p>

                    </figure>
                    <figure className="nft-card">
                        <div className="nft-img">
                            <img src="/img/PandulumCarOverload.jpg" alt="PA-Cars-Overload-Original #1"/>
                        </div>
                        <figcaption className="nft-detail">
                            <p>1.00 ETH</p>
                            <p>opensea.io</p>
                        </figcaption>
                        <p className="nft-name">Overload #1</p>
                        <p className="nft-coll">Unique Artworks</p>
                        <p className="nft-short">Short Information about the NFT <br/>on the Marketplace</p>

                    </figure>
                    <figure className="nft-card">
                        <div className="nft-img">
                            <img src="/img/freako.jpg" alt="NFT1"/>
                        </div>
                        <figcaption className="nft-detail">
                            <p>0.1 ETH</p>
                            <p>opensea.io</p>
                        </figcaption>
                        <p className="nft-name">#0023</p>
                        <p className="nft-coll">Freakos Collection</p>
                        <p className="nft-short">Short Information about the NFT <br/>on the Marketplace</p>

                    </figure>
                </div>
            </div>
        </section>
    );
};

export default Pandulum;