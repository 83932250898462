import scrollToElement from "./SmoothScrollClick";

export function handleScroll() {
    let lastPos = 0;
    let monitor = window.innerWidth;

    window.addEventListener('scroll', function () {
        let aktPos = window.pageYOffset;
        let navContainer = document.getElementById('nav-container');

        if (navContainer && aktPos > 25 && aktPos > lastPos && monitor > 991) {
            navContainer.classList.add('nav-dis');
        } else if (navContainer) {
            navContainer.classList.remove('nav-dis');
        }
        lastPos = aktPos;
    });
}

export default handleScroll;
