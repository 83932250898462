import Footer from "../components/Footer/Footer";
const Imprint = () => {
    return (
        <>
            <section className="imprint">
                <article className="imprint-container">
                    <h1>Impressum</h1>

                    <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
                    <p>Ren&eacute; Tornow<br/>
                        NFT Creator, Lettering Artist, Frontend-Developer, Grafikdesigner<br/>
                        Siedlungstr. 10<br/>
                        82538 Geretsried</p>

                    <h3>Kontakt</h3>
                    <p>Telefon: &#91;0049 15678 942157&#93;<br/>
                        E-Mail: hello@pandulum-arts.com</p>

                    <h3>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h3>
                    <p><strong>Name und Sitz des Versicherers:</strong><br/>
                        ADVOCARD Rechtsschutzversicherung AG<br/>
                        Besenbinderhof 43<br/>
                        20097 Hamburg</p>
                    <h3>EU-Streitschlichtung</h3>
                    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                        href="https://ec.europa.eu/consumers/odr/" target="_blank"
                        rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/> Unsere E-Mail-Adresse
                        finden Sie oben im Impressum.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>

                </article>
            </section>
            <Footer/>
        </>
    )
};

export default Imprint;