import React, { useEffect } from 'react';

const SkillsComponent = () => {

    useEffect(() => {
        const handleScroll = () => {
            const progressElements = document.querySelectorAll<HTMLElement>('.progress');
            const windowHeight = window.innerHeight;

            progressElements.forEach((progressElement) => {
                const skillspostop = progressElement.offsetTop;
                const action = skillspostop - (windowHeight * 0.7);

                if (window.pageYOffset > action) {
                    progressElement.classList.add('go');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="myskills">
            <div className="skills-container">
                <h2>Fachliche Kompetenzen und Lebenslauf</h2>
                <div className="skills">
                    <p>Adobe CC (Photoshop, Illustrator, InDesign, XD)</p>
                    <p className="progress eins"></p>
                    <p>HTML</p>
                    <p className="progress zwei"></p>
                    <p>CSS</p>
                    <p className="progress drei"></p>
                    <p>JavaScript</p>
                    <p className="progress vier"></p>
                    <p>REACT</p>
                    <p className="progress fuenf"></p>
                    <div className="skills-legenede">
                        <p>0%</p><p>100%</p>
                    </div>
                </div>
                <div className="skill-text">
                    <h4>Studium</h4>
                    <p>Akademie U5 - Diplom (Note 1,6)<br />
                        Kommunikationsdesigner</p>
                    <br />
                    <h4>Beruf</h4>
                    <h5>Deutsche Telekom AG</h5>
                    <p>Abgeschlossene Berufsausbildung (3 Jahre)<br />
                        IT-Systemelektroniker</p>
                    <h5>Babylon GmbH München</h5>
                    <p>Software Vertrieb & Over-Use Management (2,5 Jahre)</p>
                    <h5>Interone GmbH München</h5>
                    <p>Praktikum Kreation Art (8 Monate)</p>
                    <h5>Heye GmbH München</h5>
                    <p>Trainee Kreation Art (12 Monate)</p>
                    <h5>Brandcom München GmbH</h5>
                    <p>Art Director & Senior Art Director - (7,5 Jahre)</p>
                </div>
            </div>
        </section>
    );
};

export default SkillsComponent;