import { useEffect, useState } from 'react';

const useBurgerMenu = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const windowSize = window.matchMedia('(max-width: 991px)');

        const handleNavCheck = () => {
            const navBar = document.querySelector('.nav-bar') as HTMLElement;
            const navCheck = document.querySelector('#nav-check') as HTMLInputElement;

            if (!isMenuOpen) {
                navBar.style.transform = 'translateY(0%)';
                navCheck.checked = true;
            } else {
                navBar.style.transform = 'translateY(-100%)';
                navCheck.checked = false;
            }

            setMenuOpen(!isMenuOpen);
        };

        const handleLinkClick = () => {
            const navBar = document.querySelector('.nav-bar') as HTMLElement;
            const navCheck = document.querySelector('#nav-check') as HTMLInputElement;

            navBar.style.transform = 'translateY(-100%)';
            navCheck.checked = true;

            setMenuOpen(false);
        };

        if (windowSize.matches) {
            const navCheck = document.querySelector('#nav-check') as HTMLInputElement;
            const menuLinks = document.querySelectorAll('menu li a');

            navCheck.addEventListener('click', handleNavCheck);

            menuLinks.forEach((link) => {
                link.addEventListener('click', handleLinkClick);
            });
        }

        return () => {
            if (windowSize.matches) {
                const navCheck = document.querySelector('#nav-check') as HTMLInputElement;
                const menuLinks = document.querySelectorAll('menu li a');

                navCheck.removeEventListener('click', handleNavCheck);

                menuLinks.forEach((link) => {
                    link.removeEventListener('click', handleLinkClick);
                });
            }
        };
    }, [isMenuOpen]);

    return isMenuOpen;
};

export default useBurgerMenu;
