import {Link} from "react-router-dom";
import scrollToElement from "../../helperFunctions/SmoothScrollClick";
const Footer = () => {
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (

        <footer id="footer">
            <div className="footer">
                <div className="footer-left-col">
                    <nav id="bot-nav">
                        <menu className="footer-list">
                            <li>
                                <Link to="/" onClick={() => scrollToElement('photography')}>Hobby</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => scrollToElement('myskills')}>Skills</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => scrollToElement('references')}>Referenzen</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => scrollToElement('nft-label')}>NFT-Label</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => scrollToElement('topstop')}>Top</Link>
                            </li>
                        </menu>
                    </nav>
                    <p className="copyright">&copy;René Tornow 2023 <span>|</span>
                        <Link to="/imprint">Impressum</Link><span>|</span>
                        <Link to="/privacy-policy">Datenschutzerklärung</Link></p>
                </div>
                <div className="footer-form">
                    <h4 className="footer-text">Interesse am Portfolio?</h4>
                    <p>Schicken Sie mir eine kurze Nachricht an <a href="mailto:portfolio@renetornow.de">portfolio@renetornow.de </a>
                        und ich sende Ihnen mein Portfolio als PDF zu. Natürlich
                        nur einen Auszug sodass wir bei einem persölichen Termin noch etwas zu sehen und besprechen haben.</p>

                    {/*<form action="php/phpform_mailer.php" method="post">*/}
                    {/*    <label>*/}
                    {/*        <input type="text" placeholder="Ihr Name" name="Name"/>*/}
                    {/*    </label>*/}
                    {/*    <label>*/}
                    {/*        <input type="email" placeholder="Ihre Emailadresse" name="E-Mail"/>*/}
                    {/*    </label>*/}
                    {/*    <label>*/}
                    {/*        <textarea name="message" id="" cols="30" rows="5"*/}
                    {/*                  placeholder="Ihre Nachricht..."></textarea>*/}
                    {/*    </label>*/}
                    {/*    <input type="submit" value="Absenden"/>*/}
                    {/*</form>*/}
                </div>
            </div>
        </footer>

);
};

export default Footer;