import React from "react";

const Hobby = () => {
    return (
        <>
        <section id="photography">
            <div className="photography-container">
                <div className="int-trio-cont">
                    <div className="trio-back"><img src="/img/Lettering1.webp" alt="Naturfoto"/></div>
                    <div className="trio-mid"><img src="/img/Lettering3.webp" alt="Naturfoto 2"/></div>
                    <div className="trio-front"><img src="/img/Lettering2.webp" alt="Naturfoto 3"/></div>
                </div>
                <div className="int-txt-cont">
                    <h2>Lettering</h2>
                    <h3>Schriftgestaltung & Logo-Schriftzüge </h3>
                    <p>Meine Leidenschaft ist es, analog/digital zu zeichnen oder zu malen. Die Begeisterung, Einzigartiges zu schaffen ist hier mein Antrieb. Wenn es anderen dann noch Freude bereitet oder einen professionellen Logo-Schriftzug liefert, freut mich das immer sehr.</p>
                </div>
            </div>
        </section>
        </>
    );
};

export default Hobby;