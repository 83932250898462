import Footer from "../components/Footer/Footer";
const PrivacyPolicy = () => {
    return (
        <>
    <section className="ppolicy">
        <div className="ppolicy-container">
            <h1>Privacy policy</h1>

            <h2>1. Data protection at a glance</h2>
            <h3>General information</h3>
            <p>The following notes give a simple overview of what happens to your personal data when you visit this
                website. Personal data is all data with which you can be personally identified. Detailed information on
                the subject of data protection can be found in our privacy policy listed below this text.</p>

            <h3>Data collection on this website</h3>
            <h4>Who is responsible for data collection on this website?</h4>
            <p>The data processing on this website is carried out by the website operator. Its contact details can be
                found in the section "Note on the responsible body" in this data protection declaration.</p>

            <h4>How do we collect your data?</h4>
            <p>On the one hand, your data is collected by you communicating it to us. Here it can be e.g. B. data that
                you enter into a contact form.</p>
            <p>Other data is collected automatically or with your consent when you visit the website by our IT systems.
                These are mainly technical data (e.g. B. Inter net browser, operating system or time of page view). This
                data is collected automatically as soon as you enter this website.</p>

            <h4>What do we use your data for?</h4>
            <p>Part of the data is collected to ensure error-free provision of the website. Other data can be used to
                analyze your user behavior.</p>

            <h4>What rights do you have regarding your data?</h4>
            <p>You have the right at any time to receive information free of charge about the origin, recipient and
                purpose of your stored personal data. You also have the right to request the correction or deletion of
                this data. If you have given your consent to data processing, you can revoke
                this consent at any time for the future. In addition, you have the right to request the restriction of
                the processing of your personal data in certain circumstances.</p>
            <p>Furthermore, you have the right of appeal to the competent supervisory authority.
                You can contact us at any time for this as well as for further questions on the subject of data
                protection.</p>

            <h3>Analysis tools and third-party tools</h3>
            <p>When you visit this website, your surfing behavior can be statistically evaluated. This is mainly done
                with so-called analysis programs.
                Detailed information on these analysis programs can be found in the following privacy policy.</p>

            <h2>2. Hosting</h2>
            <h3>External hosting</h3>
            <p>This website is hosted by an external service provider (hoster). The personal data collected on this
                website is stored on the hoster's servers. This can be v.&nbsp;a. IP addresses, contact requests, Meta
                and communication data,
                contract data, contact data, names, website accesses and other data generated via a website.</p>
            <p>The use of the hoster takes place for the purpose of fulfilling the contract vis-à-vis our potential and
                existing customers (Art. 6 para. 1 lit. b GDPR) and in the interest of a secure, fast and efficient
                provision of our online
                offer by a professional provider (Art. 6 para. 1 lit. f GDPR). If a corresponding consent has been
                requested, the processing takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25
                para. 1 TTDSG, insofar as
                the consent is the storage of cookies or access to information in the user's terminal device (e.g. B.
                Device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.</p>
            <p>Our hoster will only process your data to the extent necessary to fulfill its performance obligations and
                follow our instructions in relation to this data.</p>
            <p>We use the following hoster:</p>
            <p>manitu GmbH<br/>
                Welvertstra&szlig;e 2<br/>
                66606 St. Wendel<br/>
                Germany</p>

            <h4>Order processing</h4>
            <p>We have concluded a contract for order processing (AVV) with the above-mentioned provider. This is a
                contract required under data protection law, which guarantees that it processes the personal data of our
                website visitors only
                according to our instructions and in compliance with the GDPR.</p>

            <h2>3. General information and mandatory information</h2>
            <h3>Data protection</h3>
            <p>The operators of these pages take the protection of your personal data very seriously. We treat your
                personal data confidentially and in accordance with the statutory data protection regulations and this
                data protection declaration.</p>
            <p>When you use this website, various personal data is collected. Personal data is data with which you can
                be personally identified. This privacy policy explains what data we collect and what we use it for. It
                also explains how and for what purpose this is done.</p>
            <p>We would like to point out that data transmission over the Internet (e.g. B. when communicating by
                e-mail) may have security gaps. A complete protection of the data against access by third parties is not
                possible.</p>

            <h3>Note on the responsible body</h3>
            <p>The responsible body for data processing on this website is:</p>
            <p>Ren&eacute; Tornow<br/>
                Siedlungstr. 10<br/>
                82538 Geretsried, Germany</p>

            <p>E-Mail: hello@pandulum-arts.com</p>
            <p>The responsible body is the natural or legal person who alone or jointly with others about the purposes
                and means of processing personal data (e.g. B. Names, e-mail addresses, etc. Etc.) decides.</p>

            <h3>Storage period</h3>
            <p>If no more specific storage period has been specified in this data protection declaration, your personal
                data will remain with us until the purpose for data processing ceases to apply. If you make a
                legitimate deletion request or revoke your consent to data processing, your data will be deleted unless
                we have other legally permissible reasons for storing your personal data (e.g. B. tax or commercial
                retention periods); in the latter case, the deletion takes place after these reasons have expired.</p>

            <h3>General information on the legal basis for data processing on this website</h3>
            <p>If you have consented to the data processing, we process your personal data on the basis of Art. 6 para.
                1 lit. a GDPR or Art. 9 para. 2 lit. a GDPR, provided that special categories of data according to Art.
                9 para. 1 GDPR.
                In the case of express consent to the transfer of personal data to third countries, data processing is
                also carried out on the basis of Art. 49 para. 1 lit. a GDPR. If you are involved in the storage of
                cookies or in access
                to information in your end device (e.g. B. via device fingerprinting), the data processing is
                additionally carried out on the basis of § 25 para. 1 TTDSG. The consent can be revoked at any time. If
                your data is necessary for
                the fulfillment of the contract or for the implementation of pre-contractual measures, we process your
                data on the basis of Art. 6 para. 1 lit. b GDPR. Furthermore, we process your data if it is necessary to
                fulfill a legal
                obligation on the basis of Art. 6 para. 1 lit. c GDPR. The data processing can also be carried out on
                the basis of our legitimate interest in accordance with Art. 6 para. 1 lit. f GDPR. The relevant legal
                bases in each individual
                case are provided in the following paragraphs of this data protection declaration.</p>

            <h3>Note on data transfer to the USA and other third countries</h3>
            <p>Among other things, we use tools from companies based in the USA or other third countries that are not
                secure under data protection law. If these tools are active, your Personal data will be transferred to
                these third countries and
                processed there. We would like to point out that no level of data protection comparable to the EU can be
                guaranteed in these countries.</p>

            <p>For example, US companies are obliged to disclose personal data to security authorities without you as
                the data subject being able to take legal action against it. It cannot therefore be ruled out that US
                authorities
                (e.g. B. Intelligence services) process, evaluate and permanently store your data on US servers for
                monitoring purposes. We have no influence on these processing activities.</p>

            <h3>Revocation of your consent to data processing</h3>
            <p>Many data processing operations are only possible with your express consent. You can revoke an already
                given consent at any time. The legality of the data processing carried out until the revocation remains
                unaffected by the revocation.</p>

            <h3>Right to object to data collection in special cases as well as to direct advertising (Art. 21 GDPR)</h3>
            <p>IF THE DATA PROCESSING IS BASED ON ART. 6 PARA. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE
                PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS
                ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS.
                THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS DATA PROTECTION
                DECLARATION. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA CONCERNED, UNLESS WE CAN
                DEMONSTRATE COMPELLING LEGITIMATE REASONS FOR THE PROCESSING THAT
                OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR THE PROCESSING SERVES TO ASSERT, EXERCISE OR DEFEND
                LEGAL CLAIMS (OBJECTION PURSUANT TO ART. 21 PARA. 1 GDPR).</p>
            <p>IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT MARKETING PURPOSES, YOU HAVE THE RIGHT TO OBJECT AT ANY
                TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH ADVERTISING; THIS ALSO
                APPLIES TO PROFILING, INSOFAR AS IT IS RELATED TO SUCH
                DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL THEN NO LONGER BE USED FOR THE PURPOSE OF
                DIRECT ADVERTISING (CONTRADICTION ACCORDING TO ART. 21 PARA. 2 GDPR).</p>

            <h3>Right of appeal to the competent supervisory authority</h3>
            <p>In the event of violations of the GDPR, the persons concerned have the right of appeal to a supervisory
                authority, in particular in the Member State of their habitual residence, place of work or place of
                alleged infringement. The right of appeal is without prejudice
                to other administrative or judicial remedies.</p>

            <h3>Right to data portability</h3>
            <p>You have the right to have data that we process automatically on the basis of your consent or in
                fulfillment of a contract handed over to yourself or to a third party in a common, machine-readable
                format. If you request the direct transfer of the data to another person
                responsible, this will only be done if it is technically feasible.</p>

            <h3>Information, deletion and correction</h3>
            <p>Within the framework of the applicable legal provisions, you have the right at any time to free
                information about your stored personal data, its origin and recipient and the purpose of data processing
                and, if necessary, a right to correction or deletion of this data.
                For this and other questions on the subject of personal data, you can contact us at any time.</p>

            <h3>Right to restriction of processing</h3>
            <p>You have the right to request the restriction of the processing of your personal data. You can contact us
                at any time. The right to restriction of processing exists in the following cases:</p>
            <ul>
                <li>If you dispute the accuracy of your personal data stored by us, we
                    usually need time to verify this. For the duration of the examination, you have the right to request
                    the restriction of the processing of your personal data.
                </li>
                <li>If the processing of your personal data was/is unlawful, you can request the restriction of data
                    processing instead of deletion.
                </li>
                <li>If we no longer need your personal data, but you need it to exercise, defend or assert legal claims,
                    you have the right to request
                    the restriction of the processing of your personal data instead of deletion.
                </li>
                <li>If you have an objection in accordance with Art. 21 para. 1 GDPR, a balance must be made between
                    your and our interests. As long as it is not yet clear whose interests prevail, you have the right
                    to request the restriction of the processing of your personal data.
                </li>
            </ul>
            <p>If you have an objection in accordance with Art. 21 para. 1 GDPR, a balance must be made between your and
                our interests. As long as it is not yet clear whose interests prevail, you have the right to request the
                restriction of the processing of your personal data.</p>

            <h2>4. Data collection on this website</h2>
            <h3>Request by e-mail, telephone or fax</h3>
            <p>If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name,
                request), will be stored and processed by us for the purpose of processing your request. We will not
                pass on this data without your consent.</p>
            <p>The processing of this data takes place on the basis of Art. 6 para. 1 lit. b GDPR, if your request is
                related to the performance of a contract or is necessary for the implementation of pre-contractual
                measures. In all other cases, the processing is based on our legitimate
                interest in the effective processing of the inquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on
                your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; the consent can be revoked at any
                time.</p>
            <p>The data sent by you to us via contact requests will remain with us until you request us to delete it,
                revoke your consent to storage or the purpose for data storage no longer applies (e.g. B. after your
                request has been processed). Mandatory statutory provisions - in particular
                statutory retention periods - remain unaffected.</p>

            <h2>5. Plugins und Tools</h2>
            <h3>Google Web Fonts</h3>
            <p>This site uses so-called web fonts provided by Google for the uniform presentation of fonts. When you
                call up a page, your browser loads the required web fonts into your browser cache to display texts and
                fonts correctly.</p>
            <p>For this purpose, the browser you are using must connect to Google's servers. This makes Google aware
                that this website was accessed via your IP address. The use of Google WebFonts is based on Art. 6 para.
                1 lit. f GDPR. The website operator has a
                legitimate interest in the uniform presentation of the typeface on its website. If a corresponding
                consent has been requested, the processing takes place exclusively on the basis of Art. 6 para. 1 lit. a
                GDPR and § 25 para. 1 TTDSG, insofar as the
                consent is the storage of cookies or access to information in the user's terminal device (e.g. B. Device
                fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.</p>
            <p>If your browser does not support web fonts, a standard font will be used by your computer.</p>
            <p>For more information about Google Web Fonts, see <a href="https://developers.google.com/fonts/faq"
                                                                   target="_blank"
                                                                   rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> and
                in Google's privacy policy: <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                                               rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
            </p>
            <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>

        </div>

    </section>
        <Footer/>
    </>
    )
};

export default PrivacyPolicy;