import React, { useState, useEffect } from 'react';

const WindowResizeListener = () => {
    const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint());

    useEffect(() => {
        const handleResize = () => {
            const newBreakpoint = getCurrentBreakpoint();
            if (newBreakpoint !== breakpoint) {
                setBreakpoint(newBreakpoint);
                // Neuladen der Seite erzwingen
                window.location.reload();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [breakpoint]);

    return null;
};

const getCurrentBreakpoint = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 992) {
        return 'xl'; // Extra Large
    } else if (windowWidth >= 577) {
        return 'lg'; // Large
    } else if (windowWidth >= 441) {
        return 'md'; // Medium
    } else {
        return 'sm'; // Small
    }
};

export default WindowResizeListener;
